
import React from "react";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import CloudNativeKick from "../sections/products/CloudNativeKick"
import PipelineDelivery from "../sections/products/PipelineDelivery"
import ConsultingServices from "../sections/products/ConsultingServices"
import FAQ from "../sections/landing3/FAQ"
import Pricing from "../sections/pricing/PricingTable2"
import VideoCTA from "../sections/landing3/VideoCTA"

const Products = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="Market Offerings"></Hero>
        <CloudNativeKick />
        <PipelineDelivery />
        <ConsultingServices />
      </PageWrapper>
    </>
  );
};
export default Products;
